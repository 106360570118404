import React from "react";
import Button from "../components/Button";
import SurveyDetail from "../components/SurveyDetail";

import chaseWhite from "../components/images/chase-foldable-electric-scooter-white-front-view.jpg";


const Survey = () => { 

    return (
    <>
        <SurveyDetail imgsrc={chaseWhite} />

        <Button colour="btn changeColour greenColour" linkText="" btnText="Proceed Checkout" id=""/>
    </>  
    )
}

export default Survey;