import React from "react";
import productsData from "./datas/productsData";
import { Link } from "react-router-dom";
import ButtonBar from "./ButtonBar";
import Button from "./Button";
import "./products.css"
import "./MainSectionImage.css"

const Products = () => {
  const products = productsData.map(product => {
    return ( 
        <div key={product.id}  className="items">
          {product.name}
          <img height="300px" className="marginTop20 width" src={product.image} />
          <p>Price: ${product.price}</p>
          <Button colour="btn changeColour greenColour" linkText={`/products/${product.id}`} btnText="Buy" id=""/>
        </div>
    );
  });

  return (
    <>
      <h1>Products</h1>
      <div className="grid-container"> 
        {products}
      </div>
    </>
  );
};

export default Products;
