import React from "react";
import ButtonBar from "./ButtonBar";
import BigHeading from "./BigHeading";
import "./MainSectionImage.css"
import ScrollAnimation from 'react-animate-on-scroll';

const MainSectionImageRight = ({linkLearnMoreText, imgsrc, buttomlearnmorecss, mainbackground, headline, subheadline, linkBuyText, headcss,subheadcss }) => {

    return (
        <div className={mainbackground}>    
            <BigHeading headline={headline} subheadline={subheadline} headcss={headcss} subheadcss={subheadcss}/>
            <ButtonBar buttomlearnmorecss={buttomlearnmorecss} buttonbuycss="btn changeColour" linkLearnMoreText={linkLearnMoreText} linkBuyText={linkBuyText}/>
       
            <div className="mainsection">
                <div className="section center reveal-text">
                    {/* <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'> */}
                    <p className="darkgrey">Mini electric mobility for gated communities, commercial premises and leisure spots.</p> 
                    {/* </ScrollAnimation> */}
                </div>
                <div className="section">
                    <img className="mainsectionimage height mix-blend-mode img-animation-scaleX" src={imgsrc} />
                </div>
            </div>
       </div>
    )
}

export default MainSectionImageRight;