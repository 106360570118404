
import redscooter from  "../images/tanqat-scooter-red.jpg"
import miniscooternobg from  "../images/mini-scooter-removebg.png";
import automaticfold from "../images/automatic-foldable-ev-scross.jpg"
import seatscootershadow from "../images/scooter-with-seat-shadow.png";
import chasemain from "../images/chase-main.jpg";
import kickscooterblueseat5 from "../images/tanqat-scooter-blue-removebg.png";
import cruiserST from "../images/cruiser-st.png"

export default [
    {
      id: "1",
      name: "Canton-X",
      description: "Everyone's favorite cat who loves to hate",
      image:seatscootershadow,
      price: 15
    },
    {
      id: "2",
      name: "Cruiser-X",
      description:
        "He bends! He stretches! He even ties in knots, but always returns to his original shape!",
        image: miniscooternobg,
      price: 20
    },
    {
      id: "3",
      name: "Cross-X",
      description:
        "It's a race, it's a chase, hurry up and feed their face! Who will win? No one knows! Feed the hungry hip-ip-pos!",
        image: automaticfold,
      price: 30
    },
    {
      id: "4",
      name: "Chase-X",
      description: "You'll get caught up in the crossfire!",
      image:chasemain,
      price: 35
    }
    ,
    {
      id: "5",
      name: "Canton-X",
      description: "You'll get caught up in the crossfire!",
      image:redscooter,
      price: 35
    }
    ,
    {
      id: "6",
      name: "Canton-X",
      description: "You'll get caught up in the crossfire!",
      image:kickscooterblueseat5,
      price: 35
    },
    {
      id: "7",
      name: "Cruiser-ST",
      description: "You'll get caught up in the crossfire!",
      image:cruiserST,
      price: 35
    }
  ];
  