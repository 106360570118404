
import MainSectionSquare from "../components/MainSectionSquare";
import chase1 from "../components/images/chase-foldable-electric-scooter-white-front-view.jpg";
import chase2 from "../components/images/chase-foldable-electric-scooter-onfoldjpg.jpg";
import chase3 from "../components/images/chase-foldable-electric-scooter-white-back-view.jpg";
import chase4 from "../components/images/chase-foldable-electric-scooter-white-side-view.jpg";

const LearnMoreChase = () => {
    return (
      <div className="background-teal-gradient">
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={chase1} linkBuyText={`/products/4`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={chase2} linkBuyText={`/products/4`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={chase3} linkBuyText={`/products/4`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={chase4} linkBuyText={`/products/4`}/>
      </div>
    )
}

export default LearnMoreChase;