import { useEffect, useRef, useState } from "react";
import {FiPlus} from 'react-icons/fi'
import "./Collapsible.css";

const Collapsible = (props) => {

    const [active, setActive] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
      
        contentRef.current.style.maxHeight = active 
        ? `${contentRef.current.scrollHeight}px`
           : "0px";
      
    }, [contentRef, active]);

    const toggleAccordion = () => {
        setActive(!active); 
    }

    return (
     <>
       <div className="center"> 
          <button
            className={`question-section ${active}`}
            onClick={toggleAccordion}
          >
            <div>
              <div className="question-align background-maroon-gradient">
                <h4 className="question-style">
                  {props.question}
                </h4>
                <FiPlus
                  className={active ? `question-icon rotate` : `question-icon`}
                />
              </div>
              <div
                ref={contentRef}
                className={active ? `answer answer-divider` : `answer`}
              >
                <p>{props.answer}</p>
              </div>
            </div>
          </button>
        </div>
     </>
    );
}

export default Collapsible;