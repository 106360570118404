import onfold from "../components/images/cross-on-fold.jpg"
import automaticfold from "../components/images/automatic-foldable-ev-scross.jpg"
import multicolour from "../components/images/tanqat-cross-foldable-scooter-multi-colour.jpg"
import riding from "../components/images/cross-best-foldable-ev-riding.jpg"
import ridinggirl from "../components/images/foldable-ev-for-adult-cross.jpg"
import MainSectionSquare from "../components/MainSectionSquare";

const LearnMoreCross = () => {
    return (
        <div className="background-teal-gradient">
        {/* <div className="backgroundblack">
          <iframe width="90%" height="438" src={iframesrc} title="Evo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div> */}
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout " squarecontainercss="squarecontainer width100 " scooterimg={ridinggirl} linkBuyText={`/products/3`}/>
         <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout " squarecontainercss="squarecontainer width100 " scooterimg={onfold} linkBuyText={`/products/3`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout " squarecontainercss="squarecontainer width100 " scooterimg={multicolour} linkBuyText={`/products/3`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={automaticfold} linkBuyText={`/products/3`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 " scooterimg={riding} linkBuyText={`/products/3`}/>
        </div>
      )
}

export default LearnMoreCross;