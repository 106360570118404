import React from "react";

import MainSectionSquare from "../components/MainSectionSquare";
import tanqatCruiserDisplay from "../components/images/tanqat-cruiser-display.jpeg";
import tanqatCruiserhandle from "../components/images/tanqat-cruiser-handle.jpeg";


const LearnMoreCruiser = () => {
    return (
      <div className="background-teal-gradient">
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 height750" scooterimg={tanqatCruiserDisplay} linkBuyText={`/products/2`}/>
        <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 height750" scooterimg={tanqatCruiserhandle} linkBuyText={`/products/2`}/>
      </div>
    )
}

export default LearnMoreCruiser;