import React from "react"
import {useParams} from "react-router-dom"
import productsData from "./datas/productsData"
import Button from "./Button";
import "./products.css"

function ProductDetail() {
    const {productId} = useParams()
    const thisProduct = productsData.find(prod => prod.id === productId)
    
    return (
        <div className="grid-container-product-detail"> 
            <div className="items">
               <img className="imgProductDetail" src={thisProduct.image} />
            </div>
            <div className="items">
                <h1>{thisProduct.name}</h1>
                
                <p>{thisProduct.description}</p>
                <p>Color: Blue</p>
                <p>Total:       ${thisProduct.price}</p>
                <Button colour="btn changeColour greenColour" linkText="" btnText="Proceed Checkout" id=""/>
            </div>
        </div>
    )
}

export default ProductDetail