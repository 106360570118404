import React from "react";
import BigHeading from "./BigHeading";
import ButtonBar from "./ButtonBar";
import Button from "./Button";

import "./MainSectionImage.css"

const MainSectionSquare = ({imagewidthsize, squarecontainercss, scooterimg, linkLearnMoreText, linkBuyText, buttomlearnmorecss}) => {

    return (
            
            <div className={squarecontainercss}>
               <img className={imagewidthsize} src={scooterimg} />
               <BigHeading headline="Foldable e-bike" subheadline="small" headcss="headline fontcolour top-centered display-none" subheadcss=" subheadline fontcolour top-centered display-none"/>
               {/* <ButtonBar buttomlearnmorecss={buttomlearnmorecss} buttonbuycss="btn changeColour bottom-centered-buy greenColour" linkLearnMoreText={linkLearnMoreText} linkBuyText={linkBuyText}/> */}
               <Button id="" colour="btn changeColour bottom-centered-buy greenColour" btnText="Buy" linkText={linkBuyText} />
            </div>
    )
}

export default MainSectionSquare;