import React from "react";
import "./BigHeading.css";

const BigHeading = ({headline, subheadline, headcss, subheadcss}) => {

    return (
    <div>
        <p className={headcss}>{headline}</p>
        <p className={subheadcss}>{subheadline}</p>
    </div>
    )
}

export default BigHeading;