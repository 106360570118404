import React from "react"
import BigHeading from "./BigHeading"
import ButtonBar from "./ButtonBar"
import "./MainSectionImage.css"

const MainSectionImageLeft = ({imgsrc, linkLearnMoreText, buttomlearnmorecss, mainbackground, headline, subheadline, linkBuyText, imgwidth}) => {

    return (
        <div className={mainbackground}>
            <BigHeading headline={headline} subheadline={subheadline} headcss="headline white animateHeader" subheadcss=" subheadline white animateSubHeader"/>
            <ButtonBar buttonlearncss="btn fixedColour" buttomlearnmorecss={buttomlearnmorecss} buttonbuycss="btn changeColour" linkLearnMoreText={linkLearnMoreText} linkBuyText={linkBuyText}/>
       
            <div className="mainsection reveal-text-container">
                <div className="section">
                    <img className="img-animation-scaleX" width={imgwidth} src={imgsrc} />
                </div>
                <div className="section center reveal-text white">Mini electric mobility for gated communities, commercial premises and leisure spots. <span class="reveal-bg"></span></div>

            </div>
     </div>
    )
}

export default MainSectionImageLeft;