import React from "react";
import Button from "./Button";

const ButtonBar = ({buttomlearnmorecss, buttonbuycss, linkLearnMoreText, linkBuyText}) => {

    return (
    <div className="">
      <Button id="" colour={buttomlearnmorecss} btnText="Learn more" linkText={linkLearnMoreText}/>
      <Button id="" colour={buttonbuycss} btnText="Buy" linkText={linkBuyText} />
      </div>
    )
}

export default ButtonBar;