
import { BrowserRouter as Router,  Link,  Route, Routes } from "react-router-dom";
import { useState } from "react";
import './App.css';
import Home from './Pages/Home';
import NavBar from './components/NavBar';
import ProductDetail from "./components/ProductDetail";
import Products from "./components/Products";
import redscooter from  "./components/images/tanqat-scooter-red.jpg"
import Survey from "./Pages/Survey";
import LearnMoreCruiser from "./Pages/LearnMoreCruiser";
import LearnMoreCanton from "./Pages/LearnMoreCanton";
import LearnMoreCross from "./Pages/LearnMoreCross";
import LearnMoreChase from "./Pages/LearnMoreChase";

function App() {
  const [btnState, setBtnState] = useState(true);
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}

<Router>
    <NavBar setBtnState= {setBtnState} btnState={btnState}/> 
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/learn-more-canton" element={<LearnMoreCanton iframesrc="https://www.youtube.com/embed/PloM9U4Vqxg" imgsrc={redscooter} />} />
      <Route path="/learn-more-cruiser" element={<LearnMoreCruiser />} />
      <Route path="/learn-more-cross" element={<LearnMoreCross />} />
      <Route path="/learn-more-chase" element={<LearnMoreChase />} />
      <Route path="/products" element={<Products buttomlearnmorecss="btn fixedColour bottom-centered-learn" />} />
      <Route path="/products/:productId" element={<ProductDetail/>} />
      <Route path="/survey" element={<Survey />} />
    </Routes> 
  </Router>
    </div>
  );
}

export default App;
