import React from "react";
import { useEffect, useState } from "react";
import MainSectionImageRight from "../components/MainSectionImageRight";
import MainSectionImageLeft from "../components/MainSectionImageLeft";
import MainSectionSquare from "../components/MainSectionSquare";
import Collapsible from "../components/Collapsible";
import faqs from "../components/datas/faqs";

import funscooterhd from "../components/images/tanqat-fun-scooter-hd.png";
import chasemain from "../components/images/chase-main.jpg";
import miniscooternobg from  "../components/images/mini-scooter-removebg.png";
import seatscootershadow from "../components/images/scooter-with-seat-shadow.png";
import cruiserST from "../components/images/cruiser-st.png";
import BigHeading from "../components/BigHeading";
import "../components/MainSectionImage.css"

const Home = () => {

    return (
        <>
           <MainSectionImageLeft headline="Cross-X" subheadline="Premium foldable bike" mainbackground="background-teal-gradient" buttomlearnmorecss="btn fixedColour" imgwidth="130%"  imgsrc={funscooterhd} linkLearnMoreText='/learn-more-cross' linkBuyText={`/products/3`}/>
           <MainSectionImageRight headcss="headline black animateHeader" subheadcss="subheadline black animateSubHeader" headline="Cruiser-X" subheadline="Ease your ride" mainbackground="background-orange-gradient " buttomlearnmorecss="btn fixedColour" imgsrc={miniscooternobg} linkLearnMoreText='/learn-more-cruiser' linkBuyText={`/products/2`}/>
           <MainSectionImageLeft headline="Canton-X" subheadline="Fold with fun" mainbackground="background-maroon-gradient" buttomlearnmorecss="btn fixedColour" imgwidth="130%"  imgsrc={seatscootershadow} linkLearnMoreText='/learn-more-canton' linkBuyText={`/products/1`}/>
           <MainSectionImageRight headcss="headline white animateHeader" subheadcss="subheadline white animateSubHeader"  headline="Chase-X" subheadline="Speed smooth" mainbackground="background-black-gradient" buttomlearnmorecss="btn fixedColour" imgsrc={chasemain} linkLearnMoreText='/learn-more-chase' linkBuyText={`/products/4`}/>
           <MainSectionImageLeft headline="Cruiser-ST" subheadline="Feel the thrill" mainbackground="background-teal-gradient" buttomlearnmorecss="btn fixedColour" imgwidth="80%"  imgsrc={cruiserST} linkLearnMoreText='/learn-more-cross' linkBuyText={`/products/7`}/>

       {/*  <MainSectionImageLeft buttomlearnmorecss="btn fixedColour"  imgsrc={funscooter} linkLearnMoreText='/learn-more-auto-fold'/>  */}
        {/* <CustomSlider>
        {eimages.map((image, index) => {
          return <img className="serviceimg" key={index} src={image.imgURL} alt={image.imgAlt} />;
        })}
      </CustomSlider> */}

        <div className="background-maroon-gradient">
           <BigHeading headline="Frequently asked questions" subheadline="" headcss="headline black" subheadcss=" subheadline black"/>
           {
               faqs.map((faq) => {
                    console.log(faq);
                    return <Collapsible question={faq.question} answer={faq.answer}/>
                 })
            }
      </div>
        </>
    )
    
}

export default Home;