
export default [ 
    {
      "question": "On a single charge, how far can an EV go?",
      'answer': "The size of the battery and the driving environment affect an EV's driving range. Tanqat EVs have a range of 40–80 km between charges."
    },
    {
      "question": "How much does it cost to reserve the Tanqat EV?",
      'answer': "Booking amount is 500rs refundable. "
    },
    {
      "question": "EV can be purchased online or offline?",
      'answer': "It can be booked online only."
    },
    {
      question: "Can we customise the battery specs?",
      answer: "Yes, battery is customisible based on customer demand."
    }, 
    {
      "question": "Kind of battery",
      'answer': "LiFePO4."
    },
  {
    "question": "What's your deposit/cancellation/refund policy?",
    'answer': "100% refund policy if cancelled before 48 hours. Afterwards no refund. "
  }
  ]