import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";

const Button = ({colour, btnText, id, linkText}) => {

    return (
        <>
        <Link to={linkText}>
           <button id={id} className={colour} >{btnText}</button>   
        </Link>  
        </>)
    
}

export default Button;