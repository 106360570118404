import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

function NavBar({setBtnState, btnState}) {
  const [click, setClick] = useState(false);

  const handleClick = (e) => {
  
    setClick(!click);
  }

  const handleNavClick = (e) => {
    setClick(!click);
    setBtnState(true);
  }

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <div>
             <NavLink exact to="/" className="nav-logo">tanQat</NavLink>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact to="/"
                activeClassName="active"
                className="nav-links"
                onClick={(e) => handleNavClick(e)}
              >
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                exact to="/products"
                activeClassName="active"
                className="nav-links"
                onClick={(e) => handleNavClick(e)}
              >
                Products
              </NavLink>
            </li>

            {
            btnState ? <li className="nav-item">
              
               <a href="#gallery-mehfil-banquet-in-hazaribagh" activeClassName="active"
                className="nav-links" onClick={(e) => handleNavClick(e)}>Care</a>
             
            </li> : ""
          }

{
            btnState ?  <li className="nav-item">
            <a href="#charges-mehfil-banquet-in-hazaribagh" activeClassName="active"
                className="nav-links" onClick={(e) => handleNavClick(e)}>Leasing</a>
            
            </li>:""
}
{ 
            btnState ?  <li className="nav-item">
              <a href="#contactus-mehfil-banquet-in-hazaribagh" activeClassName="active"
                className="nav-links" onClick={(e) => handleNavClick(e)}>Connect</a>
            
            </li> : ""
}
          </ul>
          <div className="nav-icon" onClick={(e) => handleClick(e)}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
