import React, { useState } from "react";

const SurveyDetail = ({imgsrc}) => {
    const [gender, setGender] = useState("");

return (
        <div className="grid-container-product-detail"> 
            <div className="items">
               <img src={imgsrc} height="180px" width="36%"/>
            </div>
            <div className="items" >
                
                <input type="radio" value="Male" name="gender" checked={gender === "Male"} /> Male
            </div>
        </div> 
    )
}

export default SurveyDetail;