import React from "react";

import MainSectionSquare from "../components/MainSectionSquare";
import kickscootergirl1 from "../components/images/best-foldable-ev-canton-riding.png";
import kickscooterseat1 from "../components/images/no-kick-seat1.jpeg";

const LearnMoreCanton = () => {
    return (
      <div className="background-teal-gradient">
      <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 height750" scooterimg={kickscootergirl1} linkBuyText={`/products/2`}/>
       <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 height750" scooterimg={kickscooterseat1} linkBuyText={`/products/2`}/>
      <MainSectionSquare buttomlearnmorecss="display-none" imagewidthsize="width50 img-animation-fadeout" squarecontainercss="squarecontainer width100 height750" scooterimg={kickscooterseat1} linkBuyText={`/products/2`}/>
      </div>
    )
}

export default LearnMoreCanton;